export default {
  oz: {
    flagSrc: require("@/assets/img/svg/uzbekistan.svg"),
    name: "Ўзбекча",
    abr: "Ўз"
  },
  uz: {
    flagSrc: require("@/assets/img/svg/uzbekistan.svg"),
    name: "Uzbek",
    abr: "Uz"
  },
  ru: {
    flagSrc: require("@/assets/img/svg/russia.svg"),
    name: "Русский",
    abr: "Ру"
  },
  // qr: {
  //   flagSrc: require("@/assets/img/svg/uzbekistan.svg"),
  //   name: "Qoraqalpoqcha",
  //   abr: "Qr"
  // },
  en: {
    flagSrc: require("@/assets/img/svg/united-kingdom.svg"),
    name: "English",
    abr: "En"
  }
}
